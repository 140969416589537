import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import ArrowIcon from '@/assets/icons/ic-arrow.svg';

import { FilledButton } from '@/components/core/Button/variants/FilledButton';
import { ErrorLayout } from '@/components/ui/shared/layouts/ErrorLayout/ErrorLayout';
import { ButtonVariant } from '@/styles/themes/button';

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <ErrorLayout errorCode="404" message={t('notFoundPage.message')}>
      <Link href="/" legacyBehavior>
        <FilledButton variant={ButtonVariant.ACTION_FILLED}>
          {t('notFoundPage.buttonLabel')}
          <ArrowIcon css={{ marginLeft: 8 }} />
        </FilledButton>
      </Link>
    </ErrorLayout>
  );
};

export default NotFoundPage;
